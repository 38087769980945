import { Media } from './Media';

export enum QuestionType {
  Audio = 'audio',
  WebImage = 'web',
  Text = 'text',
  Spelling = 'spelling',
  Photo = 'photo',
}

export enum AnswerType {
  Text = 'text',
  MultipleChoice = 'multiplechoice',
}

export interface MultipleChoice {
  options: string[];
  correctIndex: number;
}

export interface FlashCard<T extends QuestionType, K extends AnswerType> {
  id: string;
  lesson_id: string;
  question_type: T;
  question_value: T extends
    | QuestionType.Photo
    | QuestionType.Audio
    | QuestionType.WebImage
    ? Media
    : string;
  answer_type: K;
  answer_value: K extends AnswerType.MultipleChoice ? MultipleChoice : string;
  created_at: string;
  updated_at: string;
}

export interface FlashCardDto {
  id: string;
  lesson_id: string;
  question_type: QuestionType;
  question_value: string;
  answer_type: AnswerType;
  answer_value: string;
  created_at: string;
  updated_at: string;
}
