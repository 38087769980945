import React, { useContext } from 'react';
import { RxDocument } from 'rxdb';
import { useSwiperSlide } from 'swiper/react';
import { useParams } from 'react-router-dom';

import { FlashCardDocType } from '../../../../../database/schemas/flashcard';
import { AnswerType, QuestionType } from '../../../../../types/FlashCard';
import { TextAnswer } from './TextAnswer';
import { GameContext } from '../../GameContext';
import { MultipleChoice } from './MultipleChoice';
import { VoiceAnswer } from './VoiceAnswer';
import { Col, Row } from '../../../../components/Grid';
import { GameType } from '../../../../../types/Game';

interface Props {
  flashcard: RxDocument<FlashCardDocType>;
}

export function GameAnswer(props: Props) {
  const { flashcard } = props;
  const { answers } = useContext(GameContext);
  const disabled = answers[flashcard.id]?.touched;
  const { gameType } = useParams<{ gameType: GameType }>();
  const { isActive } = useSwiperSlide();
  const { question_type, question_value, answer_type, answer_value } =
    flashcard._data;

  if (gameType === GameType.SpeakGame) {
    if (question_type === QuestionType.Spelling) {
      return (
        <Row justifyContent="center">
          <Col xs={12}>
            <TextAnswer
              disabled={true}
              name="words"
              answer={question_value}
              questionType={question_type}
            />
          </Col>
          <Col gutterY={{ xs: 1, md: 1.5 }}>
            <VoiceAnswer name="words" isActive={isActive} disabled={disabled} />
          </Col>
        </Row>
      );
    }

    switch (answer_type) {
      case AnswerType.Text: {
        return (
          <Row justifyContent="center">
            <Col xs={12}>
              <TextAnswer disabled={true} name="words" answer={answer_value} />
            </Col>
            <Col gutterY={{ xs: 1, md: 1.5 }}>
              <VoiceAnswer
                name="words"
                isActive={isActive}
                disabled={disabled}
              />
            </Col>
          </Row>
        );
      }
      case AnswerType.MultipleChoice: {
        return (
          <Row justifyContent="center">
            <Col xs={12}>
              <TextAnswer disabled={true} name="words" answer={'string'} />
            </Col>
            <Col gutterY={{ xs: 1, md: 1.5 }}>
              <VoiceAnswer
                name="words"
                isActive={isActive}
                disabled={disabled}
              />
            </Col>
          </Row>
        );
      }
    }

    if (answer_type === AnswerType.Text) {
      return (
        <Row justifyContent="center">
          <Col xs={12}>
            <TextAnswer disabled={true} name="words" answer={answer_value} />
          </Col>
          <Col gutterY={{ xs: 1, md: 1.5 }}>
            <VoiceAnswer name="words" isActive={isActive} disabled={disabled} />
          </Col>
        </Row>
      );
    }
  }

  if (question_type === QuestionType.Spelling) {
    return (
      <TextAnswer
        disabled={disabled}
        name="words"
        answer={question_value}
        questionType={question_type}
      />
    );
  }

  switch (answer_type) {
    case AnswerType.Text: {
      return (
        <TextAnswer disabled={disabled} name="words" answer={answer_value} />
      );
    }
    case AnswerType.MultipleChoice: {
      return (
        <MultipleChoice
          disabled={disabled}
          answer={answer_value}
          name="optionIndex"
        />
      );
    }
  }

  return null;
}
