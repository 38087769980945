import React, { useEffect } from 'react';
import { FieldArray, FieldConfig, useField } from 'formik';
import _isArray from 'lodash/isArray';
import { useTranslation } from 'react-i18next';

import { Col, Row } from '../../../../../../components/Grid';
import { MultipleChoiceItemField } from './MultipleChoiceItemField';
import { Button } from '../../../../../../components/Button';

interface Props extends FieldConfig {}

export function MultipleChoiceField(props: Props) {
  const { t } = useTranslation();
  const { name } = props;
  const [field, meta, helpers] = useField(name);

  useEffect(() => {
    if (!_isArray(field.value?.options)) {
      helpers.setValue({
        options: [''],
        correctIndex: 0,
      });
    }
  }, []);

  return (
    <FieldArray
      name={`${name}.options`}
      render={arrayHelpers => {
        return (
          <Row gutter={0}>
            {field.value?.options?.map((item, index) => (
              <Col xs={12} key={index} gutter={0}>
                <MultipleChoiceItemField
                  {...arrayHelpers}
                  index={index}
                  name={name}
                />
              </Col>
            ))}
            <Col xs={12} gutter={{ xs: 2, md: 3 }} gutterY={{ xs: 1, md: 1.5 }}>
              <Button
                type="button"
                blue
                onClick={() => arrayHelpers.push('')}
                block
              >
                {t('button.addOption')}
              </Button>
            </Col>
            <Col
              xs={12}
              gutter={{ xs: 2, md: 3 }}
              gutterY={{ xs: 1, md: 1.5 }}
            />
          </Row>
        );
      }}
    />
  );
}
