import React from 'react';
import { useRxData } from 'rxdb-hooks';
import { useTranslation } from 'react-i18next';

import { PageWrapper } from '../../components/PageWrapper';
import { Col, Row } from '../../components/Grid';
import { NewClass } from './NewClass';
import { HomeHeader } from './Header';
import { CollectionName } from '../../../database/types';
import { ClassDocType } from '../../../database/schemas/class';
import { Container } from '../../components/Container';
import { StudyButton } from '../../components/StudyButton';
import { Heading } from '../../components/Heading';
import { ClassList } from './ClassList';

export function HomePage() {
  const { t } = useTranslation();
  const { result: classes, isFetching } = useRxData<ClassDocType>(
    CollectionName.Classes,
    collection =>
      collection.find({
        selector: {
          shared: false,
        },
        sort: [{ created_at: 'desc' }],
      }),
  );
  const { result: sharedClasses } = useRxData<ClassDocType>(
    CollectionName.Classes,
    collection =>
      collection.find({
        selector: {
          shared: true,
        },
        sort: [{ created_at: 'desc' }],
      }),
  );

  return (
    <>
      <HomeHeader />
      <PageWrapper>
        <Container $fixed>
          <Row>
            <Col xs={12}>
              <Row>
                <Col xs={12}>
                  <Row alignItems="center">
                    <Col xs="auto">
                      <Heading white level={3}>
                        {t('title.myClasses')}
                      </Heading>
                    </Col>
                    <Col>
                      <StudyButton />
                    </Col>
                    <Col>
                      {!isFetching && <NewClass isFirst={!classes.length} />}
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} gutterY={{ xs: 1, sm: 1.5 }}>
                  <Row gutterY={0.75} gutter={0}>
                    <ClassList classes={classes} />
                  </Row>
                </Col>
              </Row>
            </Col>
            {!!sharedClasses?.length && (
              <Col xs={12} gutterY={3} style={{ height: 44 }}>
                <Row>
                  <Col xs={12}>
                    <Row alignItems="center">
                      <Col xs="auto">
                        <Heading white level={3}>
                          {t('title.sharedClasses')}
                        </Heading>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} gutterY={{ xs: 1, sm: 1.5 }}>
                    <Row gutterY={0.75} gutter={0}>
                      <ClassList classes={sharedClasses} />
                    </Row>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </Container>
      </PageWrapper>
    </>
  );
}
