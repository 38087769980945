import styled, { css } from 'styled-components/macro';
import { switchProp } from 'styled-tools';

interface Props {
  background?: 'white';
  color?: 'yellow';
}

export const CircleNumber = styled.div<Props>`
  font-weight: 800;
  text-align: center;
  min-width: 2.5rem;
  line-height: 1.5rem;
  padding: 0.5rem;
  display: inline-block;
  border-radius: 1.5rem;

  color: ${switchProp('color', {
    yellow: 'var(--yellow-100)',
  })};

  ${switchProp('background', {
    white: css`
      background: var(--white);
    `,
  })};
`;

CircleNumber.defaultProps = {
  background: 'white',
  color: 'yellow',
};
