import React, {
  createContext,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';

interface ContextData {
  onFlip: () => void;
  flipped: boolean;
  flippable: boolean;
  setFlip: (value: boolean) => void;
}

interface ContextProps {
  flippable?: boolean;
  flipped?: boolean;
}

export const LessonCardContext = createContext({} as ContextData);

const defaultProps = {
  flippable: false,
  flipped: false,
};

export const LessonCardContextProvider = (
  props: PropsWithChildren<ContextProps> & typeof defaultProps,
) => {
  const [flipped, setFlipped] = useState(props.flipped);
  const { flippable } = props;

  useEffect(() => {
    setFlipped(props.flipped);
  }, [props.flipped]);

  const onFlip = () => {
    setFlipped(!flipped);
  };

  const setFlip = value => {
    setFlipped(value);
  };

  return (
    <LessonCardContext.Provider value={{ flipped, onFlip, setFlip, flippable }}>
      {props.children}
    </LessonCardContext.Provider>
  );
};

LessonCardContextProvider.defaultProps = defaultProps;
