import React from 'react';
import styled from 'styled-components/macro';
import { useRxData } from 'rxdb-hooks';
import { useParams } from 'react-router-dom';

import { GameContextProvider } from './GameContext';
import { GameType } from '../../../types/Game';
import { LessonDocType } from '../../../database/schemas/lesson';
import { CollectionName } from '../../../database/types';
import { FlashCardDocType } from '../../../database/schemas/flashcard';
import { Game } from './Game';
import { Wrapper } from '../../components/PageWrapper';

export function GamePage() {
  const params = useParams<{
    gameType: GameType;
    lessonsId: string;
  }>();

  const lessonsId = params.lessonsId?.split(',') || [];

  const { result: lessons } = useRxData<LessonDocType>(
    CollectionName.Lessons,
    collection => collection.findByIds(lessonsId),
  );

  const { result: flashcards, isFetching } = useRxData<FlashCardDocType>(
    CollectionName.FlashCards,
    collection =>
      collection.find({
        selector: {
          lesson_id: {
            $in: lessonsId,
          },
        },
      }),
  );

  return (
    <GameContextProvider>
      <PageWrapper>
        <Game
          flashcards={flashcards}
          lessons={lessons}
          isFetching={isFetching}
        />
      </PageWrapper>
    </GameContextProvider>
  );
}

const PageWrapper = styled(Wrapper)`
  padding-bottom: 0 !important;
`;
