import React from 'react';
import { RxDocument } from 'rxdb';

import { FlashCardDocType } from '../../../../../../database/schemas/flashcard';
import { QuestionType } from '../../../../../../types/Lesson';
import { Col, Row } from '../../../../../components/Grid';
import { Span } from '../../../../../components/Span';
import { Icon } from '../../../../../components/Icon';
import { Block } from '../../../../../components/Block';
import { Heading } from '../../../../../components/Heading';
import { AudioButton } from '../../../../../components/AudioButton';
import { useMediaFile } from '../../../../../hooks/useMediaFile';
import { NotSynced } from '../../../../../components/NotSynced';
import { MultipleChoice } from '../../../../../../types/FlashCard';
import { Button } from '../../../../../components/Button';
import styled from 'styled-components/macro';
import { Label } from '../../../../../components/Label';
import { useTranslation } from 'react-i18next';

interface Props {
  flashcard: RxDocument<FlashCardDocType>;
}
export function TextQuestion(props: Props) {
  const { flashcard } = props;
  const { question_type, question_value, answer_type, answer_value } =
    flashcard._data;

  return (
    <Row justifyContent="center" gutterY={1}>
      <Col gutterY={1}>
        <Span color="blue">
          {question_type === QuestionType.Spelling ? (
            <Icon name="spelling-1" $size={48} />
          ) : null}
        </Span>
      </Col>
      <Col gutterY={1} xs={12}>
        <Block textCenter>
          <Heading level={3} weight={600}>
            {question_value}
          </Heading>
        </Block>
      </Col>
    </Row>
  );
}

const AnswerOption = styled(Button)`
  pointer-events: none;
  white-space: normal;
  font-weight: 400;
  text-align: left;
  display: block;
  background: rgba(var(--blue-200-rgb), 0.1);
  color: var(--blue-200);
`;

const Circle = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  border: 2px solid var(--blue-200);
`;

export function MultipleChoiceAnswers(props: Props) {
  const { flashcard } = props;
  const { t } = useTranslation();

  const answer: MultipleChoice =
    typeof flashcard.answer_value === 'string'
      ? JSON.parse(flashcard.answer_value)
      : flashcard.answer_value;

  return (
    <Row gutterY={0.5} justifyContent="center">
      <Col gutterY={0.5} xs={12}>
        <Label>{t('label.options')}</Label>
      </Col>
      {answer.options.map((option, index) => (
        <Col key={index} gutterY={0.5} xs={12}>
          <AnswerOption block>
            <Row gutter={0.5}>
              <Col gutter={0.5}>
                <Circle />
              </Col>
              <Col gutter={0.5} xs="auto">
                {option}
              </Col>
            </Row>
          </AnswerOption>
        </Col>
      ))}
    </Row>
  );
}

export function PhotoQuestion(props: Props) {
  const { flashcard } = props;
  const { question_value } = flashcard._data;

  const { src } = useMediaFile({ path: question_value });

  if (!src) return <NotSynced />;

  return <img src={src} alt="" />;
}

export function AudioQuestion(props: Props) {
  const { flashcard } = props;
  const { question_value } = flashcard._data;

  const { src, loading } = useMediaFile({ path: question_value });

  if (!src) return <NotSynced loading={loading} />;

  return (
    <Row justifyContent="center">
      <Col>
        <AudioButton src={src} />
      </Col>
    </Row>
  );
}
