import React from 'react';

import { Container } from '../../../components/Container';
import { Logo } from '../../../components/Logo';
import { Col, Row } from '../../../components/Grid';
import { Header } from '../../../components/Header';
import { ProfileButton } from '../../../components/ProfileButton';

export function HomeHeader() {
  return (
    <Header>
      <Container>
        <Row alignItems="center" flexWrap="nowrap">
          <Col xs="auto">
            <Logo />
          </Col>
          <Col>
            <ProfileButton />
          </Col>
        </Row>
      </Container>
    </Header>
  );
}
