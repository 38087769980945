import styled, { css } from 'styled-components/macro';
import { ifProp, switchProp } from 'styled-tools';

interface Props {
  level?: 1 | 2;
  color?: 'black' | 'grey' | 'grey-light' | 'blue' | 'yellow' | 'red' | 'green';
  background?: 'black';
}

export const Span = styled.span<Props>`
  color: ${switchProp('color', {
    black: 'var(--black)',
    grey: 'var(--grey-300)',
    'grey-light': 'var(--grey-200)',
    blue: 'var(--blue-200)',
    yellow: 'var(--yellow-200)',
    red: 'var(--red)',
    green: 'var(--green-100)',
  })};

  ${ifProp(
    { level: 2 },
    css`
      font-size: 14px;
      line-height: 18px;
    `,
  )}

  ${switchProp('background', {
    black: css`
      background: var(--black);
      color: var(--white);
    `,
  })};

  ${ifProp(
    'background',
    css`
      padding: 1px 0.5rem;
      border-radius: 4px;
    `,
  )}
`;

Span.defaultProps = {
  level: 1,
};
