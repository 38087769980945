import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import { Col, Row } from '../../../../../components/Grid';
import { ChoiceOption } from './ChoiceOption';
import shuffle from 'lodash/shuffle';

interface Props {
  disabled: boolean;
  answer: any;
  name: string;
}

export const MultipleChoice = (props: Props) => {
  const { answer, name, disabled } = props;
  const { options } = answer;
  const [field, meta, helpers] = useField(name);
  const [fakeField, fakeMeta, fakeHelpers] = useField(`${name}_fake`);
  const [shuffleOptions, setShuffleOptions] = useState(options);

  useEffect(() => {
    if (!options) return;
    setShuffleOptions(shuffle(options));
  }, [options]);

  const onSelect = option => {
    const findFunc = o => o === option;
    const realIndex = options.findIndex(findFunc);
    const fakeIndex = shuffleOptions.findIndex(findFunc);
    helpers.setValue(realIndex);
    fakeHelpers.setValue(fakeIndex);
  };

  return (
    <Row
      justifyContent="center"
      flexDirection={{ xs: 'column', lg: 'row' }}
      gutterY={0.5}
      gutter={0.5}
    >
      {shuffleOptions.map((option, index) => {
        return (
          <Col key={index} gutterY={0.5} gutter={0.5}>
            <ChoiceOption
              disabled={disabled}
              option={option}
              onSelect={onSelect}
              isActive={index === fakeField.value}
            />
          </Col>
        );
      })}
    </Row>
  );
};
