import React from 'react';
import { Col, Row } from 'app/components/Grid';
import styled from 'styled-components';
import { useField, useFormikContext } from 'formik';

import Rating from '../../../../components/Rating';
import { media } from '../../../../../styles/media';
import { LessonDocType } from '../../../../../database/schemas/lesson';
import { Checkbox } from '../../components/Checkbox';
import { CheckboxWrapper } from '../../components/CheckboxWrapper';

interface Props {
  lesson: LessonDocType;
  score: number;
}

export function LessonRow(props: Props) {
  const { lesson, score } = props;
  const { title, id } = lesson;
  const name = `classes.${lesson.class_id}`;
  const [field, meta, helpers] = useField(name);
  const form = useFormikContext();

  const handleCheckboxClick = () => {
    if (field.value?.includes(id)) {
      form.setFieldValue(
        name,
        field.value.filter(lessonId => lessonId !== id),
      );
    } else {
      form.setFieldValue(name, [...(field?.value || []), id]);
    }
  };

  return (
    <Wrapper onClick={handleCheckboxClick}>
      <Row>
        <Col gutter={0.5} display="flex">
          <CheckboxWrapper>
            <Checkbox color="yellow" checked={field.value?.includes(id)} />
          </CheckboxWrapper>
        </Col>
        <Col gutter={{ xs: 0.5, md: 0 }} xs="auto" alignSelf="center">
          <ClassName>
            <Row>
              <Col xs={12} md="auto" alignSelf="center">
                <Row>
                  <Col xs={12}>
                    <b>{title}</b>
                  </Col>
                </Row>
              </Col>
              <Col alignSelf="center" gutterY={{ xs: 0.25, md: 0 }}>
                <Rating rating={score} numberOfStars={3} />
              </Col>
            </Row>
          </ClassName>
        </Col>
        <Col gutter={{ xs: 1, md: 5.25 }} gutterY={{ xs: 0.5, md: 1 }}></Col>
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  margin-top: -1px;

  &:hover {
    background-color: rgba(var(--blue-100-rgb), 0.1);
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 1rem;
    right: 1rem;
    height: 1px;
    background: var(--grey-150);

    ${media.md} {
      left: 1.5rem;
      right: 1.5rem;
    }
  }

  &:first-child {
    margin-top: 0;

    &:before {
      left: 0;
      right: 0;
    }
  }
`;

const ClassName = styled.div`
  padding: 0.5rem 0.25rem;
  ${media.md} {
    padding: 1rem 0.5rem;
  }
`;
