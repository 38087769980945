import React, { useContext, useMemo } from 'react';
import { RxDocument } from 'rxdb';
import styled, { css } from 'styled-components/macro';
import { switchProp } from 'styled-tools';
import { Col, Row } from 'app/components/Grid';

import {
  LessonCard,
  LessonCardBack,
  LessonCardContent,
  LessonCardContextProvider,
  LessonCardFooter,
  LessonCardFront,
  LessonCardLayout,
} from '../../../../components/LessonCard';
import { FlashCardDocType } from '../../../../../database/schemas/flashcard';
import { QuestionType } from '../../../../../types/Lesson';
import {
  AudioQuestion,
  PhotoQuestion,
  TextQuestion,
} from '../../../Lesson/FlashCardsPage/FlashCard/Question';
import { GameCheckButton } from './GameCheckButton';
import { Icon } from '../../../../components/Icon';
import { GameNextButton } from './GameNextButton';
import { GameContext } from '../../GameContext';
import { Heading } from '../../../../components/Heading';
import { AnswerType, MultipleChoice } from '../../../../../types/FlashCard';
import { TextToSpeech } from '../TextToSpeech';

interface Props {
  flashcard: RxDocument<FlashCardDocType>;
}

export function GameCard(props: Props) {
  const { flashcard } = props;
  const { question_type, id, answer_type, question_value, answer_value } =
    flashcard._data;
  const { answers } = useContext(GameContext);
  const touched = answers[flashcard.id]?.touched;

  const answer = useMemo(() => {
    if (question_type === QuestionType.Spelling) {
      return question_value;
    }

    switch (answer_type) {
      case AnswerType.Text: {
        return answer_value;
      }
      case AnswerType.MultipleChoice: {
        const { options, correctIndex }: MultipleChoice =
          typeof answer_value === 'string'
            ? JSON.parse(answer_value)
            : answer_value;

        return options[correctIndex];
      }
    }

    return '';
  }, [id]);

  return (
    <Wrapper>
      <LessonCardContextProvider
        flippable
        flipped={typeof touched === 'boolean' ? touched : false}
      >
        <LessonCard>
          <LessonCardFront>
            <LessonCardLayout gridRows="auto 0fr 0fr 0fr 0fr">
              <LessonCardContent>
                {question_type === QuestionType.Text ? (
                  <TextQuestion flashcard={flashcard} />
                ) : null}
                {question_type === QuestionType.Spelling ? (
                  <TextToSpeech flashcard={flashcard} />
                ) : null}
                {question_type === QuestionType.Photo ? (
                  <PhotoQuestion flashcard={flashcard} />
                ) : null}
                {question_type === QuestionType.Audio ? (
                  <AudioQuestion flashcard={flashcard} />
                ) : null}
                {question_type === QuestionType.WebImage ? (
                  <PhotoQuestion flashcard={flashcard} />
                ) : null}
              </LessonCardContent>
              <LessonCardFooter>
                <GameCheckButton />
              </LessonCardFooter>
            </LessonCardLayout>
          </LessonCardFront>
          <LessonCardBack>
            <LessonCardLayout gridRows="auto 0fr 0fr 0fr 0fr">
              <LessonCardContent>
                <Row justifyContent="center">
                  <Col>
                    {answers[flashcard.id]?.correct ? (
                      <IconWrapper name="checkmark" />
                    ) : (
                      <IconWrapper name="cross" />
                    )}
                  </Col>
                  <Col xs={12} gutterY={2}>
                    <CorrectAnswer level={2}>{answer}</CorrectAnswer>
                  </Col>
                </Row>
              </LessonCardContent>
              <LessonCardFooter>
                <GameNextButton
                  tabIndex={answers[flashcard.id]?.touched ? -1 : undefined}
                />
              </LessonCardFooter>
            </LessonCardLayout>
          </LessonCardBack>
        </LessonCard>
      </LessonCardContextProvider>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100%;
  position: relative;
`;

const IconWrapper = styled(Icon)<{ name?: string }>`
  stroke-width: 2px;

  svg {
    ${switchProp('name', {
      checkmark: css`
        color: var(--green-100);
      `,
      cross: css`
        color: var(--red);
      `,
    })};
    height: 200px;
    width: 200px;
  }
`;

const CorrectAnswer = styled(Heading)`
  text-align: center;
`;
