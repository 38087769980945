import React from 'react';
import styled, { css } from 'styled-components/macro';
import { ifProp } from 'styled-tools';

import { Button as ButtonOrigin } from '../../../../../components/Button';

interface Props {
  disabled: boolean;
  option: string;
  onSelect: (option: string) => void;
  isActive: boolean;
}
export const ChoiceOption = (props: Props) => {
  const { option, onSelect, isActive, disabled } = props;

  const onClick = () => {
    onSelect(option);
  };

  return (
    <Button
      onClick={onClick}
      whiteBorder
      block
      disabled={disabled}
      $active={isActive}
    >
      {option}
    </Button>
  );
};

const Button = styled(ButtonOrigin)<{ $active }>`
  white-space: normal;
  ${ifProp(
    '$active',
    css`
      background-color: var(--white) !important;
      color: var(--blue-200);
      cursor: default;
    `,
  )}
`;
