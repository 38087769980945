import React, { useContext } from 'react';

import { PageWrapper } from '../../components/PageWrapper';
import { AuthContext } from '../../context/AuthContext';
import { HomeHeader } from '../HomePage/Header';
import { Container } from '../../components/Container';
import { Pricing } from '../../components/Pricing';

export function PricingPage() {
  const { user } = useContext(AuthContext);

  if (
    !user ||
    !process.env.REACT_APP_STRIPE_PRICING_TABLE_ID ||
    !process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  ) {
    return null;
  }

  return (
    <>
      <HomeHeader />
      <PageWrapper>
        <Container $fixed>
          <Pricing />
        </Container>
      </PageWrapper>
    </>
  );
}
