import React, { createContext, ReactNode, useEffect, useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getAuth,
  sendSignInLinkToEmail as sendSignInLinkToEmailOrigin,
} from 'firebase/auth';

import '../../../services/firebase';
import { api } from '../../../services/api';
import { setAuthorizationHeader } from '../../../services/interceptors';
import {
  createEmailCookie,
  createTokenCookies,
  getAccessToken,
  getEmailCookie,
  getRefreshToken,
  removeTokenCookies,
} from '../../../utils/token-cookies';
import { User } from '../../../types/User';
import { useLocalStorage } from 'react-use';
import {
  SubscriptionProduct,
  SubscriptionResponse,
} from '../../../types/Subscription';

type UserUpdateFields = Pick<User, 'email' | 'name' | 'photo_src'>;

interface AuthContextData {
  sendSignInLinkToEmail: (email: string) => Promise<void>;
  signIn: (link: string) => Promise<User | null>;
  signInWithGoogle: () => Promise<User | null>;
  signOut: () => void;
  getUserData: () => void;
  updateUserData: (user: UserUpdateFields) => Promise<void | AxiosError>;
  user: User;
  accessToken: string | null;
  isAuthenticated: boolean;
  loadingUserData: boolean;
  subscription?: SubscriptionProduct | null;
}

interface AuthProviderProps {
  children: ReactNode;
}

interface CheckEmailAuthResponse {
  accessToken: string;
  refreshToken: string;
}

const auth = getAuth();

export const AuthContext = createContext({} as AuthContextData);

export function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser, removeUser] = useLocalStorage<User | null>(
    'user',
    null,
    {
      raw: false,
      serializer: user => JSON.stringify(user),
      deserializer: user => JSON.parse(user),
    },
  );

  const [subscription, setSubscription, removeSubscription] =
    useLocalStorage<SubscriptionProduct | null>('subscription', null, {
      raw: false,
      serializer: subscription => JSON.stringify(subscription),
      deserializer: subscription => JSON.parse(subscription),
    });

  const [loadingUserData, setLoadingUserData] = useState(true);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  let accessToken = getAccessToken();
  let refreshToken = getRefreshToken();
  const isAuthenticated = Boolean(user);
  const userData = user as User;

  function sendSignInLinkToEmail(email) {
    createEmailCookie(email);
    return sendSignInLinkToEmailOrigin(auth, email, {
      // TODO: Lang
      url: `${process.env.REACT_APP_URL}/auth/boarding`,
      handleCodeInApp: true,
    });
  }

  async function signIn(link) {
    try {
      const response = await api.post<CheckEmailAuthResponse>(
        `/auth/check_email_auth`,
        {
          email: getEmailCookie(),
          auth_link: link,
        },
      );

      accessToken = response.data.accessToken;
      refreshToken = response.data.refreshToken;

      createTokenCookies(accessToken, refreshToken);
      setAuthorizationHeader(api.defaults, accessToken);

      await getUserData();

      navigate('/');
    } catch (error) {
      console.error(error);
      navigate('/auth/login');
    }

    return null;
  }

  async function signInWithGoogle() {
    try {
      const searchParams = new URLSearchParams(window.location.search);
      accessToken = searchParams.get('access_token');
      refreshToken = searchParams.get('refresh_token');

      if (accessToken && refreshToken) {
        createTokenCookies(accessToken, refreshToken);
        setAuthorizationHeader(api.defaults, accessToken);
        await getUserData();
        navigate('/');
      } else {
        navigate('/auth/login');
      }
    } catch (error) {
      console.error(error);
      navigate('/auth/login');
    }

    return null;
  }

  function signOut() {
    flashUserData();
  }

  function flashUserData(pathname = '/auth/login') {
    removeTokenCookies();
    removeUser();
    removeSubscription();
    setLoadingUserData(false);
    setAuthorizationHeader(api.defaults, '');

    if (pathname.indexOf('/auth') === -1) {
      navigate(pathname);
    }
  }

  async function getSubscription() {
    try {
      const response: AxiosResponse<SubscriptionResponse> = await api.get(
        '/stripe/check-subscriptions',
      );

      setSubscription({
        id: response.data.product.id,
        metadata: {
          limit: +response.data.product.metadata.limit,
        },
      });
    } catch (error) {
      console.error(error);
    }
  }

  async function getUserData() {
    setLoadingUserData(true);

    try {
      const response: AxiosResponse<User> = await api.get('/user');
      const user = response.data;

      user && (await getSubscription());

      user && setUser(user);
    } catch (error) {
      flashUserData();
    }

    setLoadingUserData(false);
  }

  async function updateUserData(user: UserUpdateFields) {
    try {
      const response = await api.post<User>('/user', user);
      setUser(response.data);
    } catch (error) {
      return error as AxiosError;
    }
  }

  useEffect(() => {
    if (!accessToken) flashUserData(pathname);
  }, [pathname, accessToken]);

  useEffect(() => {
    const accessToken = getAccessToken();

    if (accessToken) {
      setAuthorizationHeader(api.defaults, accessToken);
      getUserData();
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user: userData,
        accessToken,
        loadingUserData,
        getUserData,
        updateUserData,
        sendSignInLinkToEmail,
        signIn,
        signInWithGoogle,
        signOut,
        subscription,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
