import React, { PropsWithChildren } from 'react';
import { FieldConfig, useField } from 'formik';
import { ifProp } from 'styled-tools';
import styled, { css } from 'styled-components/macro';

import { Icon } from '../Icon';

interface Props extends FieldConfig {
  disabled?: boolean;
}

export function RadioField(props: PropsWithChildren<Props>) {
  const { name, value, disabled } = props;
  const [field, meta, helpers] = useField(name);
  const checked = meta.value === value;

  const onChange = () => {
    helpers.setValue(value);
  };

  return (
    <Wrapper checked={checked} disabled={disabled}>
      <StyledCheckbox checked={checked}>
        <Icon name="checkmark" />
      </StyledCheckbox>
      <Input
        {...field}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
    </Wrapper>
  );
}

export const StyledCheckbox = styled.div<{ checked?: boolean }>`
  width: 28px;
  height: 28px;
  border-radius: 0.5rem;
  transition: all 100ms;
  border: 2px solid var(--blue-200);

  svg {
    visibility: ${ifProp('checked', 'visible', 'hidden')};
  }
`;

export const Wrapper = styled.label<{
  checked?: boolean;
  disabled?: boolean;
}>`
  display: inline-block;
  position: relative;
  cursor: pointer;
  padding: 8px;
  color: var(--blue-200);

  &:hover {
    ${StyledCheckbox} {
      transition: background-color 0.2s;
      background-color: rgba(var(--blue-100-rgb), 0.1);
    }
  }

  ${ifProp(
    'disabled',
    css`
      pointer-events: none;
      opacity: 0.5;
    `,
  )}
`;

const Input = styled.input.attrs({ type: 'radio' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
