import React from 'react';
import { RxDocument } from 'rxdb';
import { useTranslation } from 'react-i18next';
import { useRxData } from 'rxdb-hooks';
import styled from 'styled-components/macro';

import { Container } from '../../../../components/Container';
import { Col, Row } from '../../../../components/Grid';
import { BackButton } from '../../../../components/BackButton';
import { Icon } from '../../../../components/Icon';
import { Heading } from '../../../../components/Heading';
import { Sidebar } from '../../../../components/Sidebar';
import { Button } from '../../../../components/Button';
import { LessonDocType } from '../../../../../database/schemas/lesson';
import { ProfileButton } from '../../../../components/ProfileButton';
import { CollectionName } from '../../../../../database/types';
import { useFlashcardCount } from '../../../../hooks/useFlashcardCount';
import { useAudioCount } from '../../../../hooks/useAudioCount';
import { useBoardCount } from '../../../../hooks/useBoardCount';
import { Span } from '../../../../components/Span';
import Rating from '../../../../components/Rating';
import { ScoreDocType } from '../../../../../database/schemas/score';
import { GameType } from '../../../../../types/Game';

type Props = {
  lesson: RxDocument<LessonDocType>;
};

export function LessonSidebar(props: Props) {
  const { t } = useTranslation();
  const { lesson } = props;
  const { id, class_id, title } = lesson || {};

  const flashcardsCount = useFlashcardCount({
    classId: class_id,
    lessonId: id,
  });
  const audioCount = useAudioCount({ classId: class_id, lessonId: id });
  const boardCount = useBoardCount({ classId: class_id, lessonId: id });

  const {
    result: [score],
  } = useRxData<ScoreDocType>(CollectionName.Score, collection =>
    collection.findOne({
      selector: {
        type: GameType.CardQuiz,
        lesson_id: id,
      },
    }),
  );

  return (
    <Sidebar>
      <Container>
        <Row flexDirection="column" height="100%">
          <Col style={{ maxWidth: '100%' }}>
            <Row>
              <Col xs={12}>
                <BackButton to={`/class/${class_id}`} block withPadding>
                  <Row gutter={0.5} flexWrap="nowrap">
                    <Col gutter={0.5}>
                      <Icon name="chevron-left-1" />
                    </Col>
                    <Col xs="auto" gutter={0.5}>
                      <Row>
                        <Col xs={12}>
                          <Heading level={3}>{title}</Heading>
                        </Col>
                        <Col xs={12} gutterY={0.5}>
                          <Rating
                            rating={score?._data?.score}
                            numberOfStars={3}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </BackButton>
              </Col>
            </Row>
          </Col>
          <Col flex="auto" gutterY={4}>
            <Row gutterY={0.5}>
              <Col xs={12} gutterY={0.5}>
                <NavButton
                  big
                  border="blue"
                  to={`/lesson/${id}/flashcards`}
                  block
                >
                  <Row gutter={0.5}>
                    <Col gutter={0.5}>
                      <Icon name="stack" />
                    </Col>
                    <Col gutter={0.5} xs="auto">
                      {t('label.flashcard', { count: 2 })}
                    </Col>
                    <Col gutter={0.5}>
                      <Span>{flashcardsCount}</Span>
                    </Col>
                  </Row>
                </NavButton>
              </Col>
              <Col xs={12} gutterY={0.5}>
                <NavButton big border="red" to={`/lesson/${id}/audio`} block>
                  <Row gutter={0.5}>
                    <Col gutter={0.5}>
                      <Icon name="microphone" />
                    </Col>
                    <Col gutter={0.5} xs="auto">
                      {t('label.audio')}
                    </Col>
                    <Col gutter={0.5}>
                      <Span>{audioCount}</Span>
                    </Col>
                  </Row>
                </NavButton>
              </Col>
              <Col xs={12} gutterY={0.5}>
                <NavButton big border="green" to={`/lesson/${id}/boards`} block>
                  <Row gutter={0.5}>
                    <Col gutter={0.5}>
                      <Icon name="document-text" />
                    </Col>
                    <Col gutter={0.5} xs="auto">
                      {t('label.board', { count: 2 })}
                    </Col>
                    <Col gutter={0.5}>
                      <Span>{boardCount}</Span>
                    </Col>
                  </Row>
                </NavButton>
              </Col>
            </Row>
          </Col>
          <Col />
          <Col />
          <Col />
          <Col>
            <Row>
              <Col>
                <ProfileButton />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Sidebar>
  );
}

const NavButton = styled(Button)`
  border-color: transparent !important;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0);
  display: block;
  text-align: left;

  ${Span} {
    font-weight: 400;
  }
`;
