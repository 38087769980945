import React from 'react';
import { RxDocument } from 'rxdb';

import { FlashCardDocType } from '../../../../../../database/schemas/flashcard';
import { Heading } from '../../../../../components/Heading';
import { Col, Row } from '../../../../../components/Grid';
import { Span } from '../../../../../components/Span';
import { Icon } from '../../../../../components/Icon';
import { Block } from '../../../../../components/Block';
import { MultipleChoice } from '../../../../../../types/FlashCard';

interface Props {
  flashcard: RxDocument<FlashCardDocType>;
}
export function TextAnswer(props: Props) {
  const { flashcard } = props;

  return (
    <Row justifyContent="center">
      <Col>
        <Span color="blue">
          <Icon name="document-text" $size={48} />
        </Span>
      </Col>
      <Col gutterY={1} xs={12}>
        <Block textCenter>
          <Heading level={3} weight={600}>
            {flashcard._data.answer_value}
          </Heading>
        </Block>
      </Col>
    </Row>
  );
}

export function MultipleChoiceAnswer(props: Props) {
  const { flashcard } = props;

  const answer: MultipleChoice =
    typeof flashcard.answer_value === 'string'
      ? JSON.parse(flashcard.answer_value)
      : flashcard.answer_value;

  return (
    <Row justifyContent="center">
      <Col>
        <Span color="blue">
          <Icon name="checkmark" $size={48} />
        </Span>
      </Col>
      <Col gutterY={1} xs={12}>
        <Block textCenter>
          <Heading level={3} weight={600}>
            {answer.options[answer.correctIndex]}
          </Heading>
        </Block>
      </Col>
    </Row>
  );
}
