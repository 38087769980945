import { InputHTMLAttributes } from 'react';
import styled, { css } from 'styled-components/macro';
import { ifProp } from 'styled-tools';

/*import InputMaskOrigin from 'react-input-mask';
import NumberFormat from 'react-number-format';*/

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  invalid?: boolean;
}

export const inputStyles = css`
  background: var(--grey-100);
  padding: 10px 12px;
  font-size: 16px;
  line-height: 22px;
  color: var(--black);
  border: 1px solid ${ifProp('invalid', 'var(--red)', 'transparent')};
  font-feature-settings: 'tnum' on, 'onum' on;
  width: 100%;
  outline: none;
  border-radius: 0.5rem;

  &:hover {
    border-color: var(--grey-200);
    transition: background-color 0.2s, border-color 0.2s;
  }

  &:focus {
    border-color: var(--grey-200);
    transition: background-color 0.2s, border-color 0.2s;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px var(--grey-700) inset;
    -webkit-text-fill-color: var(--grey-700);
    border: 1px solid var(--grey-700) !important;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  &:-webkit-autofill {
    -webkit-text-fill-color: var(--black) !important;
  }

  &::placeholder {
    color: var(--grey-200);
    opacity: 1;
  }

  &[readonly] {
    border-color: var(--grey-300) !important;
  }

  &[disabled] {
    cursor: default;
    pointer-events: none;
    color: var(--grey-300);
  }
`;

export const Input = styled.input<InputProps>`
  ${inputStyles}
`;

/*export const InputMask = styled(InputMaskOrigin)<InputProps>`
  ${inputStyles}
`;

export const InputNumber = styled(NumberFormat)<InputProps>`
  ${inputStyles}
`;*/

Input.defaultProps = {
  placeholder: '',
  type: 'text',
};
