import React, { useContext } from 'react';
import { RxDocument } from 'rxdb';
import { Formik } from 'formik';
import { useSwiper } from 'swiper/react';
import styled from 'styled-components/macro';
import { Col, Row } from '../../../../components/Grid';
import { useParams } from 'react-router-dom';

import { GameCard } from '../GameCard';
import { Container } from '../../../../components/Container';
import { GameAnswer } from '../GameAnswer';
import { FlashCardDocType } from '../../../../../database/schemas/flashcard';
import { GameContext } from '../../GameContext';
import {
  AnswerType,
  MultipleChoice,
  QuestionType,
} from '../../../../../types/FlashCard';
import { GameFormValues } from '../../types';
import { GameType } from '../../../../../types/Game';
import { answerNormalize } from '../../utils';

interface Props {
  flashcard: RxDocument<FlashCardDocType>;
}
export function GameForm(props: Props) {
  const { flashcard } = props;
  const { answers, addAnswer } = useContext(GameContext);
  const swiper = useSwiper();
  const { gameType } = useParams();
  const {
    question_type,
    question_value,
    answer_type = AnswerType.Text,
    answer_value,
  } = flashcard._data;

  let initialValues = answers[flashcard.id]?.values || {};

  const onSubmit = (values: GameFormValues) => {
    const { words, optionIndex } = values;
    const wordsNormalized = answerNormalize((words || []).join(' '));

    if (answers[flashcard.id]?.touched) {
      return swiper.slideNext();
    }

    let correct = false;

    switch (answer_type) {
      case AnswerType.Text: {
        correct =
          wordsNormalized ===
          answerNormalize(
            question_type === QuestionType.Spelling
              ? question_value
              : answer_value,
          );

        break;
      }
      case AnswerType.MultipleChoice: {
        const answer: MultipleChoice =
          typeof answer_value === 'string'
            ? JSON.parse(answer_value)
            : answer_value;
        if (gameType === GameType.SpeakGame) {
          correct =
            wordsNormalized ===
            answerNormalize(answer.options[answer.correctIndex]);
        } else {
          correct = optionIndex === answer?.correctIndex;
        }
        break;
      }
    }

    addAnswer(flashcard.id, { correct, touched: true, values });
  };

  if (!initialValues?.words) {
    if (answer_type === AnswerType.Text) {
      initialValues.words = `${answer_value}`?.split(' ')?.map(() => '');
    }

    if (question_type === QuestionType.Spelling) {
      initialValues.words = `${question_value}`?.split(' ')?.map(() => '');
    }

    if (
      gameType === GameType.SpeakGame &&
      answer_type === AnswerType.MultipleChoice
    ) {
      const answer: MultipleChoice =
        typeof answer_value === 'string'
          ? JSON.parse(answer_value)
          : answer_value;
      initialValues.words = answer.options[answer.correctIndex]
        ?.split(' ')
        ?.map(() => '');
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ handleSubmit, isSubmitting, values }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <Row flexDirection="column" height="100%" flexWrap="nowrap">
              <Col xs="auto">
                <Row height="100%" flexDirection="column" alignContent="center">
                  <Col xs="auto" style={{ position: 'relative' }}>
                    <GameCard flashcard={flashcard} />
                  </Col>
                </Row>
              </Col>
              <Col gutterY={{ xs: 1, md: 1.5 }}>
                <Container>
                  <GameAnswer flashcard={flashcard} />
                </Container>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
}

const Form = styled.form`
  position: relative;
  height: 100%;

  ${Container}:after {
    content: none;
  }
`;
