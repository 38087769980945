import * as React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { To } from 'history';
import { ifProp, switchProp } from 'styled-tools';
import { media } from '../../../styles/media';

interface Props {
  iconStart?: boolean;
  iconEnd?: boolean;
  children: any;
  type?: 'button' | 'reset' | 'submit';
  default?: boolean;
  border?: boolean | 'red' | 'blue' | 'green';
  disabled?: boolean;
  to?: To;
  href?: string;
  target?: string;
  $loading?: boolean;
  block?: boolean;
  onClick?: (event: React.MouseEvent) => any;
  invalid?: boolean;
  replace?: boolean;
  yellow?: boolean;
  outlined?: boolean;
  red?: boolean;
  blue?: boolean;
  white?: boolean;
  green?: boolean;
  color?: 'blue' | 'red' | 'green';
  whiteBorder?: boolean;
  end?: boolean;
  tabIndex?: number | undefined;
  state?: object;
  className?: string;
  big?: boolean;
}

export const Button = (props: Props) => {
  const { to, href } = props;

  if (to) return <StyledLink {...props} />;
  if (href) return <Anchor rel="noreferrer" {...props} />;
  return <StyledButton {...props} />;
};

Button.defaultProps = {
  type: 'button',
  height: 40,
  $loading: false,
  block: false,
};

const spinKeyframe = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const buttonStyles = css`
  display: ${ifProp('block', 'flex', 'inline-flex')};
  align-items: center;
  white-space: nowrap;
  justify-content: center;
  text-decoration: none;
  cursor: ${ifProp('disabled', 'default', 'pointer')};
  appearance: none;
  padding: ${ifProp('big', 19, 9)}px;
  box-sizing: border-box;
  pointer-events: ${ifProp('$loading', 'none', 'auto')};
  background: transparent;
  border-radius: 0.5rem;
  color: var(--white);
  border: 1px solid transparent;
  position: relative;
  width: ${ifProp('block', '100%', 'auto')};
  line-height: 24px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  font-weight: 600;
  text-transform: capitalize;
  //box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);

  ${media.lg} {
    padding: ${ifProp('big', 14, 9)}px 14px;
  }

  &:hover {
    transition: background-color 0.2s, box-shadow 0.2s, color 0.2s;
    //box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
  }

  ${ifProp(
    '$loading',
    css`
      color: transparent !important;
    `,
  )}

  ${ifProp(
    'yellow',
    css`
      border-color: ${ifProp('invalid', 'var(--red)', 'var(--yellow-200)')};
      background: ${ifProp('invalid', 'var(--red)', 'var(--yellow-200)')};
      //box-shadow: 0 1px 1px rgba(var(--yellow-100-rgb), 0.2);

      &:hover {
        background-color: var(--yellow-100);
        //box-shadow: 0 4px 8px rgba(var(--yellow-100-rgb), 0.25);
      }

      &[disabled] {
        background-color: var(--grey-300);
        border-color: transparent;
      }

      ${ifProp(
        'disabled',
        css`
          background-color: rgba(var(--yellow-100-rgb), 0.5);
          border-color: transparent;
        `,
      )}
    `,
  )}

  ${ifProp(
    'red',
    css`
      border-color: var(--red-hover);
      background: var(--red-hover);
      //box-shadow: 0 1px 1px rgba(var(--red-rgb), 0.2);

      &:hover {
        border-color: var(--red);
        background: var(--red);
        //box-shadow: 0 4px 8px rgba(var(--red-rgb), 0.4);
      }

      &[disabled] {
        border-color: var(--grey-600);
        background-color: var(--grey-600);
        color: var(--grey-400);
      }
    `,
  )}

  ${ifProp(
    'blue',
    css`
      border-color: var(--blue-200);
      background: var(--blue-200);
      //box-shadow: 0 1px 1px rgba(var(--blue-200-rgb), 0.2);

      &:hover {
        border-color: var(--blue-100);
        background: var(--blue-100);
        //box-shadow: 0 4px 8px rgba(var(--blue-200-rgb), 0.4);
      }

      &[disabled] {
        border-color: var(--grey-600);
        background-color: var(--grey-600);
        color: var(--grey-400);
      }
    `,
  )}

  ${ifProp(
    'green',
    css`
      border-color: var(--green-200);
      background: var(--green-200);
      //box-shadow: 0 1px 1px rgba(var(--green-200-rgb), 0.2);

      &:hover {
        border-color: var(--green-100);
        background: var(--green-100);
        //box-shadow: 0 4px 8px rgba(var(--green-200-rgb), 0.4);
      }

      &[disabled] {
        border-color: var(--grey-600);
        background-color: var(--grey-600);
        color: var(--grey-400);
      }
    `,
  )}

  ${ifProp(
    'white',
    css`
      border-color: var(--white);
      background: var(--white);
      color: var(--blue-200);
      //box-shadow: none;

      &:hover {
        border-color: var(--blue-10);
        background: var(--blue-10);
        //box-shadow: 0 4px 8px rgba(var(--blue-200-rgb), 0.4);
      }

      &[disabled] {
        border-color: var(--grey-600);
        background-color: var(--grey-600);
        color: var(--grey-400);
      }
    `,
  )}

  ${ifProp(
    'whiteBorder',
    css`
      color: var(--white);
      background-color: transparent;
      border-color: var(--white);

      &:hover {
        background-color: rgba(var(--white-rgb), 0.2);
      }

      &.active {
        border-color: transparent;
        background-color: rgba(var(--black-rgb), 0.2);
        cursor: default;
      }
    `,
  )}

  ${ifProp(
    'border',
    css`
      border-color: ${ifProp('invalid', 'var(--red)', 'var(--grey-200)')};
      color: var(--grey-300);

      &:hover {
        background-color: var(--grey-100);
      }

      &[disabled] {
        border-color: var(--grey-600);
        color: var(--grey-400);
      }
    `,
  )}

  ${switchProp('border', {
    red: css`
      border-color: var(--red-hover);
      //box-shadow: 0 1px 1px rgba(var(--red-rgb), 0.2);
      color: var(--red-hover);

      &:hover {
        border-color: var(--red);
        background: rgba(var(--red-rgb), 0.1);
        //box-shadow: 0 4px 8px rgba(var(--red-rgb), 0.4);
      }

      &.active {
        background: var(--red);
        border-color: var(--red);
        color: var(--white);
      }
    `,
    blue: css`
      border-color: var(--blue-200);
      //box-shadow: 0 1px 1px rgba(var(--blue-200-rgb), 0.2);
      color: var(--blue-200);

      &:hover {
        border-color: var(--blue-100);
        background: rgba(var(--blue-200-rgb), 0.1);
        //box-shadow: 0 4px 8px rgba(var(--blue-200-rgb), 0.4);
      }

      &.active {
        background: var(--blue-200);
        border-color: var(--blue-200);
        color: var(--white);
      }
    `,
    green: css`
      border-color: var(--green-200);
      box-shadow: 0 1px 1px rgba(var(--green-200-rgb), 0.2);
      color: var(--green-200);

      &:hover {
        border-color: var(--green-100);
        background: rgba(var(--green-200-rgb), 0.1);
        //box-shadow: 0 4px 8px rgba(var(--green-200-rgb), 0.4);
      }

      &.active {
        background: var(--green-200);
        border-color: var(--green-200);
        color: var(--white);
      }
    `,
  })}

  ${ifProp(
    'disabled',
    css`
      pointer-events: none;
    `,
  )}
  &[disabled] {
    pointer-events: none;
  }

  svg {
    width: 24px;
    height: 24px;
    margin-right: ${ifProp('iconStart', '0.5rem', '0')};
    margin-left: ${ifProp('iconEnd', '0.5rem', '0')};
  }

  &:after {
    ${ifProp(
      '$loading',
      css`
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: solid 2px rgba(255, 255, 255, 0.2);
        border-top-color: #fff;
        animation: ${spinKeyframe} 1s infinite linear;
        position: absolute;
        left: 50%;
        top: 50%;
        margin: -10px 0 0 -10px;
      `,
    )}
  }
`;

export const StyledLink = styled(({ disabled, height, ...props }) => (
  <NavLink {...props} />
))`
  ${buttonStyles}
`;

export const Anchor = styled.a`
  ${buttonStyles}
`;

export const StyledButton = styled.button<Props>`
  ${buttonStyles}
`;
