import React from 'react';
import { useAsync, useAsyncFn } from 'react-use';
import { useRxCollection, useRxData } from 'rxdb-hooks';
import { FlashCardDocType } from '../../database/schemas/flashcard';
import { CollectionName } from '../../database/types';
import { LessonDocType } from '../../database/schemas/lesson';

interface Props {
  classId: string;
  lessonId?: string;
}

export function useFlashcardCount(props: Props) {
  const { classId, lessonId } = props;

  const flashCardCollection = useRxCollection<FlashCardDocType>(
    CollectionName.FlashCards,
  );

  const lessonCollection = useRxCollection<LessonDocType>(
    CollectionName.Lessons,
  );

  const { value: count } = useAsync(async () => {
    let lessonsId = lessonId ? [lessonId] : [];

    if (!lessonId && lessonCollection) {
      const lessonsQuery = await lessonCollection.find({
        selector: {
          class_id: classId,
        },
      });

      const lessons = await lessonsQuery.exec();

      lessonsId = lessons.map(lesson => lesson._data.id);
    }

    if (flashCardCollection) {
      const query = flashCardCollection.count({
        selector: {
          lesson_id: {
            $in: lessonsId,
          },
        },
      });

      const count = await query.exec();

      return count;
    }

    return undefined;
  }, [flashCardCollection, lessonCollection, classId, lessonId]);

  return count || 0;
}
